@font-face {
  font-family: 'marianne-bold';
  src: url('../fonts/marianne-bold-webfont.woff2') format('woff2'),
    url('../fonts/marianne-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'marianne-regular';
  src: url('../fonts/marianne-regular-webfont.woff2') format('woff2'),
    url('../fonts/marianne-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html {
  background: rgb(249, 249, 249);
}

@supports (font: -apple-system-body) {
  html {
    font: -apple-system-body !important;
  }
}

body {
  margin: 0em;
  background: rgb(249, 249, 249);
  padding: env(safe-area-inset-top) env(safe-area-inset-right)
    env(safe-area-inset-bottom) env(safe-area-inset-left);
}

form,
.block,
#alert-print {
  margin: 20px;
}

h1 {
  font-size: 2em;
  color: #222;
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  h1 {
    font-size: 2.5em;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  h1 {
    font-size: 3em;
  }
}

svg {
  height: 1em;
}

h1.flex.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid rgb(242, 243, 244);
  box-shadow: rgba(51, 51, 51, 0.08) 0px 4px 8px;
  margin: 0;
}

h2 {
  font-size: large;
  color: #222;
}

footer {
  margin-top: 20px;
  background: white;
}

canvas {
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

a {
  color: rgb(255, 77, 0);
  text-decoration: underline;
}

a:hover {
  color: rgb(0, 212, 156);
}

p {
  color: #222;
}

.wrapper {
  position: relative;
  z-index: 1;
  max-width: 700px;
  margin: -100px auto 20px;
  box-shadow: rgba(51, 51, 51, 0.08) 0px 10px 24px;
  background: white;
  border: 1px solid rgb(242, 243, 244);
  border-radius: 6px;
}

@media (max-width: 800px) {
  .wrapper {
    margin: -60px 20px 20px;
  }
}

#form-profile {
  padding: 24px;
  position: relative;
  background: rgb(249, 249, 249);
  border-left: 3px solid rgb(0, 212, 156);
}

#form-profile .form-radio .form-check {
  margin-bottom: 1rem;
}

#form-profile .form-radio-label .form-check-label {
  font-weight: 400;
  transform: translateY(-2px);
}

#form-generate .form-radio .form-check {
  margin: 10px;
}

.row {
  margin: 0;
}

.form-group {
  flex: 1 0 50%;
  max-width: 50%;
}

@media (max-width: 800px) {
  .row {
    flex-direction: column;
  }

  .form-group {
    flex: 1 1 100%;
    max-width: 100%;
  }
}

#alert-facebook {
  position: fixed;
  z-index: 10;
  top: 20px;
  left: 20px;
  right: 20px;
}

#alert-print {
  margin: 1rem auto 0;
}

#date-selector-group {
  position: relative;
  overflow: hidden;
}

#date-selector-group-sortie {
  position: relative;
  overflow: hidden;
}

#date-selector {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%); /* center the input to avoid reset buttons */
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

#date-selector-sortie {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  transform: translateX(-50%); /* center the input to avoid reset buttons */
  opacity: 0;
  z-index: 1;
  cursor: pointer;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

::placeholder {
  font-style: italic;
}

.hidden {
  display: none !important;
}

.exemple {
  margin-bottom: 0;
  font-style: italic;
  color: #999999;
  height: 1em;
}

.covid-title {
  padding: 0.3em;
  font-family: 'marianne-regular', Arial, Helvetica, sans-serif;
  font-size: 1.5em;
}

.covid-subtitle {
  font-size: 1em;
  padding: 0.3em;
  text-transform: uppercase;
}

.note-title {
  border-bottom: 3px solid #fff;
}

.highlighted {
  font-weight: bold;
  border-bottom: 2px solid;
}

.footnote-title {
  color: #fff;
  font-size: 0.9em;
}

.msg-info {
  padding: 24px;
  text-align: left;
  font-family: Chivo, sans-serif;
  margin: 0;
}

.msg-alert {
  text-align: left;
  color: #222;
  font-style: italic;
}

.text-desc {
  font-style: italic;
  font-size: 0.8em;
}

.btn-attestation,
.btn-info {
  padding: 0.8em;
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  background-color: rgb(255, 77, 0);
  border-radius: 0.5em;
  border-color: white;
}

.btn-info {
  margin: 0 auto;
  padding: 0.5em;
  font-size: 0.9em;
  margin-top: 0.5em;
}

.btn-attestation:hover,
.btn-attestation:focus,
.btn-info:hover,
.btn-info:focus {
  background-color: rgba(255, 77, 0, 0.9);
  border-color: white;
}

.btn-clear-data {
  margin: 30px auto;
  max-width: 500px;
  padding: 0.8em;
  font-size: 1.2em;
  font-weight: bold;
  color: #222;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 0.5em;
  border-color: #ccc;
}

.btn-clear-data:hover,
.btn-clear-data:focus {
  color: #222;
  background-color: rgba(255, 255, 255, 0.7);
  border-color: #ccc;
}

.github {
  margin: 0.3em 0;
  font-size: 0.7em;
  text-align: center;
  color: #222;
}

.github-link:hover {
  text-decoration: underline;
}

.label-mi {
  text-align: center;
  font-size: 1em;
  color: #222;
}

.validity {
  display: inline-flex;
  justify-content: center;
  width: 2em;
}

main {
  position: relative;
}

.main-picture {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: rgba(46, 64, 83, 0.8);
  height: 200px;
  max-height: 100%;
  width: 100%;
  z-index: 0;
  background-image: url('/assets/header.jpeg');
}

.main-picture::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  background-color: rgba(88, 102, 117, 0.93);
}

.main-title {
  padding: 0.5em 0;
  z-index: 1;
  position: relative;
  max-width: 700px;
  text-align: left;
  margin: 0 auto;
  color: white;
}

@media (max-width: 800px) {
  .main-title {
    margin: auto 20px;
  }
}

.main-footer {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 2em;
  padding-bottom: 2em;
  background-color: rgb(88, 102, 117);
}

.footer-links {
  margin: 0 auto;
  max-width: 500px;
  background-color: rgb(88, 102, 117);
}

.footer-line {
  display: block;
  margin: 0.75em;
  font-size: 0.9em;
  color: white;
}

.footer-link:hover {
  text-decoration: underline;
  color: white;
}

.footer-link:focus {
  color: white;
}

.footer-logo {
  margin: 20px auto;
}

.num-08 {
  font-weight: bold;
}

.num-08:hover {
  text-decoration: underline;
}

.stores-link:hover {
  text-decoration: underline;
}

.confidentialite {
  .cookies {
    border-collapse: collapse;

    td,
    th {
      border: 1px solid rgb(255, 77, 0);
      padding: 8px;
    }

    .header-row {
      font-weight: bold;
      color: #ffffff;
      background-color: rgb(255, 77, 0);
      text-transform: uppercase;
      text-align: center;
    }

    .name-col {
      font-weight: bold;
      padding: 8px 16px;
      color: rgb(255, 77, 0);
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    .btn-generateur {
      padding: 0.8em;
      font-size: 1.2em;
      font-weight: bold;
      color: #ffffff;
      background-color: rgb(255, 77, 0);
      border-radius: 0.5em;
      text-decoration: none;
      color: #ffffff;
    }
  }
  em {
    font-size: 0.8rem;
  }
}

.sous-titre {
  position: relative;
  font-weight: bold;
  font-size: 1rem;
  line-height: 20px;
  color: #222;
  padding-left: 12px;
  margin-bottom: 12px;
}

.sous-titre::before {
  content: ' ';
  position: absolute;
  top: 50%;
  left: 0;
  width: 4px;
  height: 4px;
  transform: translateY(-50%);
  border-radius: 50%;
  margin-right: 8px;
  background-color: rgb(0, 212, 156);
}

.titre-2 {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  color: #222;
}

.titre-3 {
  text-align: left;
  font-size: 1.25rem;
  font-weight: bold;
  color: #222;
}

.titre-4 {
  text-align: left;
  font-size: 1rem;
  font-weight: bold;
  color: #222;
}

@media (max-width: 800px) {
  table thead {
    display: none;
  }

  table tr {
    display: block;
    margin-bottom: 40px;
  }

  table td {
    display: flex;
    text-align: left;
    min-height: 3rem;
  }

  table td:before {
    content: attr(data-label);
    font-weight: bold;
    color: rgb(255, 77, 0);
    width: 100px;
    margin-right: 8px;
    flex-shrink: 0;
  }
}

#snackbar,
#snackbar-cleardata {
  min-width: 250px;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 16px;
  position: fixed;
  z-index: 10;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-size: 17px;
  transform: translateX(-50%);
  box-shadow: 0 0 8px 1px #fff;
  opacity: 0;
  transition: all 0.5s ease-in-out;
}

#snackbar.show,
#snackbar-cleardata.show {
  opacity: 1;
}

@media only screen and (min-width: 600px) {
  .hide-on-desktop,
  * [aria-labelledby='hide-on-desktop'] {
    display: none;
    max-height: 0;
    overflow: hidden;
  }
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

[id^='footnote'] {
  margin: 30px auto;
  max-width: 400px;
  font-size: 0.8em;
  color: #222;
}

#update-alert {
  position: fixed;
  z-index: 10;
  bottom: 1em;
  left: 50%;
  transform: translateX(-50%);
}

@media (max-width: 800px) {
  #update-alert {
    width: 90%;
  }
}

.alert-info {
  display: flex;
  flex-direction: column;
  color: #222;
  background-color: #ccc;
  border-color: #ccc;
}

.basis-100 {
  flex-basis: 100%;
}

.fieldset {
  margin-left: -1.25em;
  margin-right: -1.25em;
  padding-left: 1.25em;
  padding-right: 1.25em;
  border: 3px solid transparent;
}

.legend {
  padding-left: 0.5em;
}

.fieldset-error {
  border: 3px solid red;
}

.danger-emoji {
  font-weight: 400;
  font-family: apple color emoji, segoe ui emoji, noto color emoji,
    android emoji, emojisymbols, emojione mozilla, twemoji mozilla,
    segoe ui symbol;
}
